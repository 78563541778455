import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Dai Foundation`}</h1>
    <p>{`The Dai Foundation is based in Denmark and is independent of the Maker Foundation. `}</p>
    <p>{`The independent and self-governing Dai Foundation was formed to house the Maker community's key intangible assets, such as trademarks and code copyrights, and it operates solely on the basis of objective and rigid statutes that define its mandate.`}</p>
    <p>{`As defined in the Dai Foundation Trust Deed, its purpose is to safeguard what cannot be technologically decentralized in the Maker Protocol.`}</p>
    <p>{`See the December 2019 `}<a parentName="p" {...{
        "href": "https://forum.makerdao.com/t/announcing-the-dai-foundation/1046"
      }}>{`Announcing the Dai Foundation`}</a>{` forum post and the December 2019 `}<a parentName="p" {...{
        "href": "https://blog.makerdao.com/the-maker-foundation-transfers-trademarks-and-software-ip-to-independent-dai-foundation/"
      }}>{`The Maker Foundation Transfers Trademarks and Software IP to Independent Dai Foundation`}</a>{` blog post for more details.`}</p>
    <h2>{`Who is on the Board of Trustees of the Dai Foundation?`}</h2>
    <p>{`Initially, half of the Dai Foundation’s board comprises external and independent board members, whilst the other half of the board is made up of private individuals related to the Maker Foundation.`}</p>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <ul>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Søren Peter Nielsen`}</strong>{`, Chairman, Head of Product at Maker Foundation`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Jacek Czarnecki`}</strong>{`, Deputy Chairman, Global Legal Counsel at Maker Foundation`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Hans Henrik Hoffmeyer`}</strong>{`, Co-founder of Coinify`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Michael Dunworth`}</strong>{`, Co-founder of Wyre`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Payam Samarghandi`}</strong>{`, Attorney at Law at Horten Law Firm`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li"><strong parentName="p">{`Wouter Kampmann`}</strong>{`, Head of Engineering at Maker Foundation`}</p>
        </li>
      </ul>
    </Box>
    <p>{`The Dai Foundation is set up to ensure that the independent board members have the necessary understanding of the Dai system and its mechanisms. `}</p>
    <p>{`Over time, board members affiliated with the Maker Foundation will be replaced by independent board members.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      